import React from 'react';
import { render } from 'react-dom';

import { startRouter } from 'mobx-router';
// import devtoolsDetector from 'devtools-detector';
import store from './store';
import RootStore from './store';
import routes from './routes';
import './ui/global.scss';
import './ui/safari-styles.scss';
import 'slick-carousel/slick/slick.css';
import { DndProvider } from 'react-dnd';
import { sentryInit } from './sentry';
import Keycloak from 'keycloak-js';
import { LOCAL_STORAGE_KEYS, LocalStorage } from './common/localStorage';
import { TouchBackend } from './ui/TouchBackend';
import inobounce from './common/inobounce';
import { PLATFORM_URLS } from './common/platformUrls';
import App from './App';
import '../catchInsertBeforeNullParentError';
import corpStore from './store/CorpStore';
import { TRAINING_CHAPTER_TYPE } from './store/constants';

inobounce(window);

if (console.everything === undefined) {
  console.everything = [];

  console.defaultLog = console.log.bind(console);
  console.log = function () {
    console.everything.push({ type: 'log', datetime: new Date().toISOString(), value: Array.from(arguments) });
    console.defaultLog(...arguments, new Date().toISOString());
  };
  console.defaultError = console.error.bind(console);
  console.error = function () {
    console.everything.push({ type: 'error', datetime: new Date().toISOString(), value: Array.from(arguments) });
    console.defaultError(...arguments);
  };
  console.defaultWarn = console.warn.bind(console);
  console.warn = function () {
    console.everything.push({ type: 'warn', datetime: new Date().toISOString(), value: Array.from(arguments) });
    console.defaultWarn(...arguments);
  };
  console.defaultDebug = console.debug.bind(console);
  console.debug = function () {
    console.everything.push({ type: 'debug', datetime: new Date().toISOString(), value: Array.from(arguments) });
    console.defaultDebug(...arguments);
  };
}

// devtoolsDetector.addListener(() => {
//   devtoolsDetector.stop();
//   console.log('HELLO WORLD!');
//   LocalStorage.USER_TOKEN = 'fake_token'; // чтобы перебросило сразу на платформу, а не на access-denied
//   store.appStore.closeSession();
// });
// devtoolsDetector.launch();

const startApp = (disableLoader = false) => {
  sentryInit();
  startRouter(routes, store);

  const isAutotestPipelineRunning = LocalStorage.IS_AUTOTEST_PIPELINE_RUNNING;
  if (isAutotestPipelineRunning !== 'undefined' && JSON.parse(isAutotestPipelineRunning)) {
    disableLoader = true;
  }

  // playing around in the console
  window.store = store;
  window.corpStore = corpStore;
  window.store.appStore.changeCurrentPageLanguage(document.documentElement.lang);

  const observer = new MutationObserver((event) => {
    window.store.appStore.changeCurrentPageLanguage(document.documentElement.lang);
  });
  observer.observe(document.documentElement, {
    attributes: true,
    attributeFilter: ['lang'],
    childList: false,
    characterData: false,
  });

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  render(
    <DndProvider
      backend={TouchBackend}
      options={{
        enableMouseEvents: true,
        touchSlop: 30,
      }}
    >
      <App disableLoader={disableLoader} />
    </DndProvider>,
    document.getElementById('root'),
  );
};

if (location.pathname.startsWith('/play')) {
  LocalStorage.LTD_ARCHIVE = '';
  LocalStorage.WAS_TRAINING = '';
  LocalStorage.TRAINING_TYPE = '';
  LocalStorage.USER_TOKEN = '';
  LocalStorage.USER_TOKEN_TRAINING = '';
  LocalStorage.BLOCKED_COMPANIES = '';
  LocalStorage.BLOCKED_BY_COMPANIES = '';
}
console.log('ENV:', PLATFORM_URLS);
if (LocalStorage.WAS_TRAINING) {
  LocalStorage.WAS_TRAINING = '';
  LocalStorage.GAME_UID = '';
  LocalStorage.USER_UID = '';

  const currentTrainingType = (() => {
    const path = location.pathname;
    if (path.includes('training')) {
      const trainingType = _.last(path.split('/'));
      if (Object.values(TRAINING_CHAPTER_TYPE).includes(trainingType)) {
        return trainingType;
      }
    } else if (LocalStorage.TRAINING_TYPE) {
      return LocalStorage.TRAINING_TYPE;
    }
    return null;
  })();
  location.href = (() => {
    const baseHref = LocalStorage.USER_TOKEN_TRAINING ? '/training' : '/start-training';
    if (currentTrainingType) {
      return baseHref + `/${currentTrainingType}`;
    } else {
      return baseHref + `/${TRAINING_CHAPTER_TYPE.BASIC}`;
    }
  })();
  LocalStorage.TRAINING_TYPE = '';
} else {
  if (
    (!Object.keys(localStorage).some((key) => key.startsWith('kc-callback')) &&
      !location.hash &&
      !LocalStorage.NEED_KEYCLOAK &&
      !location.pathname.includes('training')) ||
    location.pathname.includes('start') ||
    location.pathname.includes('start-training')
  ) {
    startApp(false);
  } else {
    let initOptions = {
      url: PLATFORM_URLS.AUTH,
      realm: 'Alabuga',
      clientId: 'alb-game',
      onLoad: 'login-required',
    };

    let keycloak = new Keycloak(initOptions);

    keycloak
      .init({
        onLoad: initOptions.onLoad,
        // token: LocalStorage.USER_TOKEN_TRAINING || LocalStorage.USER_TOKEN,
        // refreshToken: LocalStorage.REFRESH_TOKEN,
      })
      .then((auth) => {
        if (!auth) {
          startApp();
          setTimeout(() => {
            store.goTo(routes.accessDenied);
          });
        } else {
          console.info('Authenticated');
          console.log('keycloak', keycloak);

          LocalStorage.USER_TOKEN = keycloak.token;
          LocalStorage.REFRESH_TOKEN = keycloak.refreshToken;
          LocalStorage.USER_TOKEN_TRAINING = keycloak.token;
          LocalStorage.USER_UID = keycloak.tokenParsed.sub;
          LocalStorage.remove(LOCAL_STORAGE_KEYS.NEED_KEYCLOAK);

          let alreadyStartApp = false;
          const checkRefresh = () => {
            keycloak
              .updateToken(70)
              .then((refreshed) => {
                if (refreshed) {
                  console.info('Token refreshed' + refreshed);
                  if (LocalStorage.USER_TOKEN) {
                    LocalStorage.USER_TOKEN = keycloak.token;
                    LocalStorage.REFRESH_TOKEN = keycloak.refreshToken;
                  }
                  LocalStorage.USER_TOKEN_TRAINING = keycloak.token;
                  LocalStorage.USER_UID = keycloak.tokenParsed.sub;
                  if (window.AlbChat) {
                    window.AlbChat.setToken(LocalStorage.USER_TOKEN);
                  }
                } else {
                  console.warn(
                    'Token not refreshed, valid for ' +
                      Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) +
                      ' seconds',
                  );
                }
                if (!alreadyStartApp) {
                  startApp();
                  alreadyStartApp = true;
                }
              })
              .catch(() => {
                console.error('Failed to refresh token');
                if (!alreadyStartApp) {
                  startApp();
                  alreadyStartApp = true;
                }
              });
          };
          // checkRefresh();
          if (!alreadyStartApp) {
            startApp();
            alreadyStartApp = true;
          }
          //Token Refresh
          setInterval(() => {
            checkRefresh();
          }, 10000);
        }
      })
      .catch(() => {
        console.error('Authenticated Failed');
        startApp();
        setTimeout(() => {
          store.goTo(routes.accessDenied);
        });
      });
  }
}
