import MYAU from '../assets/audio/sounds/sicknessCat.mp3';
import COIN_CLINK from '../assets/audio/sounds/coinsClink.mp3';
import LOT_UPDATED from '../assets/audio/sounds/lotUpdated.mp3';
import MODAL_ACCEPTED from '../assets/audio/sounds/modalAccepted.mp3';
import MODAL_DENIED from '../assets/audio/sounds/modalDenied.mp3';
import CATS_MATING from '../assets/audio/sounds/catsMating.mp3';
import GAME_FAILURE from '../assets/audio/sounds/gameFailure.mp3';
import MEDAL_PLACE from '../assets/audio/sounds/medalPlace.mp3';
import NON_MEDAL_PLACE from '../assets/audio/sounds/nonMedalPlace.mp3';
import SEASON_CHANGE from '../assets/audio/sounds/seasonChange.mp3';
import CAT_BORN from '../assets/audio/sounds/catBorn.mp3';

import INSIDE from '../assets/audio/music/06Trade.mp3';
import MAIN from '../assets/audio/music/01MainRoom.mp3';
import NURSERY from '../assets/audio/music/05Nursery.mp3';
import SHOP from '../assets/audio/music/03Store.mp3';
import _ from 'lodash';

export const AUDIO_LIST = {
  MYAU,
  COIN_CLINK,
  LOT_UPDATED,
  MODAL_ACCEPTED,
  MODAL_DENIED,
  CATS_MATING,
  GAME_FAILURE,
  MEDAL_PLACE,
  NON_MEDAL_PLACE,
  SEASON_CHANGE,
  CAT_BORN,
};

export const MUSIC_LIST = {
  INSIDE,
  MAIN,
  NURSERY,
  SHOP,
};

export const REQUEST_TYPE = {
  BUY: 'buy',
  SELL: 'sell',
  SHOWCASE: 'showcase',
};

export const ROOT_PATHS = {
  ACCESS_DENIED: '/access-denied',
  START: '/start',
  START_TRAINING: '/start-training',
  MAIN: '/',
  TRAINING: '/training',
  SHOP: '/shop',
  NURSERY: '/nursery',
  INSIDE: '/inside',
  LONG_DEAL: '/long-deal',
  STORAGE: '/storage',
  CEO: '/ceo',
  CITY: '/city',
  TRANSPORTATION: '/transportation',
  TRANSFER: '/transfer',
};

export const CAT_GENDER = {
  MALE: 'male',
  FEMALE: 'female',
};

export const CAT_COLOR = {
  BLACK: 'black',
  GRAY: 'gray',
  WHITE: 'white',
  GINGER: 'ginger',
};

export const CATS_TEMPLATE = _.flatten(
  Object.values(CAT_COLOR).map((color) => {
    return Object.values(CAT_GENDER).map((gender) => {
      return { color, gender };
    });
  }),
);

export const LTD_CONTENT_TYPE = {
  BUY: 'buy',
  SELL: 'sell',
  BUY_SELL: 'buy_sell',
};

export const LTD_CONTENT_NAME = {
  [LTD_CONTENT_TYPE.BUY]: 'покупка',
  [LTD_CONTENT_TYPE.SELL]: 'продажа',
  [LTD_CONTENT_TYPE.BUY_SELL]: 'покупка и продажа',
};

export const LTD_CONTENT_NAME_ACCUSATIVE_CASE = {
  [LTD_CONTENT_TYPE.BUY]: 'на покупку',
  [LTD_CONTENT_TYPE.SELL]: 'на продажу',
  [LTD_CONTENT_TYPE.BUY_SELL]: 'на покупку и продажу',
};

export const LTD_ACTIONS = {
  PREPAID: 'prepaid',
  TO_STORAGE: 'to_storage',
  STORAGE_CONFIRMATION: 'storage_confirmation',
  PAYMENT_BEFORE_SHIPMENT: 'payment_before_shipment',
  SHIPPING: 'shipping',
  PAYMENT_AFTER_SHIPMENT: 'payment_after_shipment',
  PAYMENT_OF_FINE: 'payment_of_fine',
  TERMINATING: 'terminating',
};

export const LTD_ACTIONS_STATUS = {
  PENDING: 'pending',
  COMPLETED: 'completed',
};

export const LTD_ACTIONS_NAME = {
  [LTD_ACTIONS.PREPAID]: 'Оплата аванса',
  [LTD_ACTIONS.TO_STORAGE]: 'Отправить котиков на склад',
  [LTD_ACTIONS.STORAGE_CONFIRMATION]: 'Осмотр котиков',
  [LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT]: 'Осмотр котиков и оплата перед отгрузкой',
  [LTD_ACTIONS.SHIPPING]: 'Отгрузить котиков',
  [LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT]: 'Оплата после отгрузки',
};

export const EDITABLE_FIELD = {
  SHIPPING_SEASON: 'shipping_season',
  STORAGE_TIMER: 'storage_timer',
  SHIPPING_TIMER: 'shipping_timer',

  PREPAID: 'prepaid',
  PAYMENT_BEFORE_SHIPMENT: 'payment_before_shipment',
  PAYMENT_AFTER_SHIPMENT: 'payment_after_shipment',

  MONEY: 'money',
  SEASON: 'season',
  TIMER: 'timer',

  OVERDUE_PAYMENT_LIABILITY: 'overdue_payment_liability',
  OVERDUE_DELIVERY_LIABILITY: 'overdue_delivery_liability',
  LOW_QUALITY_LIABILITY: 'low_quality_liability',

  LOW_QUALITY_TYPE: 'low_quality_type',
  PAYMENT_TYPE: 'payment_type',
  VALUE: 'value',

  BANK_GUARANTEE: 'bank_guarantee',
  INITIATOR_BANK_GUARANTEE: 'initiator_bank_guarantee',
  PARTNER_BANK_GUARANTEE: 'partner_bank_guarantee',
  AMOUNT: 'amount',
  LAST_PAYMENT_SEASON: 'last_payment_season',

  ONE_WAY_TERMINATION: 'one_way_termination',
  TWO_WAY_TERMINATION: 'two_way_termination',
  IS_TWO_WAY: 'is_two_way',
};

export const REACT_SELECT_MENU_POSITION = {
  AUTO: 'auto',
  BOTTOM: 'bottom',
  TOP: 'top',
};

const getTimerId = (id) => {
  return `timer__${id}`;
};
const LTDActionTimers = {};
Object.values(LTD_ACTIONS).forEach((actionName) => {
  LTDActionTimers[actionName] = getTimerId(actionName);
});
export const TIMER_ID = {
  HEADER: {
    SESSION_TIMER: getTimerId('session'),
  },
  LTD: {
    ACTIONS: LTDActionTimers,
  },
  LOT: getTimerId('lot'),
};

export const AI_TYPE = {
  HUMAN: 'human',
  FIFTH_SHOP: '5-th_shop',
  NORMAL_SHOP_BOT: 'normal_shop_bot',
  NURSERY_BOT: 'nursery_bot',
};

export const GAME_OVER = {
  BANKRUPTCY: 'bankruptcy',
  CEO_BANKRUPTCY: 'ceo_bankruptcy',
  NETWORK_TIMEOUT: 'network_timeout',
  PLAYER_TIMEOUT: 'player_timeout',
  MANAGER_END_GAME: 'manager_end_game',
  NO_MORE_SEASONS: 'no_more_seasons',
  INDUSTRIAL_FIRE: 'industrial_fire',
  TUTORIAL_TIME_OVER: 'tutorial_time_over',
};

export const PLAYER_NAME = {
  LEOPOLD: 'Леопольд',
  TOM: 'Том',
  BRITISH: 'Британец',
  MATILDA: 'Матильда',
  GARFIELD: 'Гарфилд',
  SIAMESE: 'Сиамский',
  MAINE_COON: 'Мейн-кун',
  KITTEN_WOOF: 'Котенок Гав',
  FELIX: 'Феликс',
  SIMBA: 'Симба',
  SPHINX: 'Сфинкс',
  MATROSKIN: 'Матроскин',
  FRIEND: 'Друг',
  LOYALTY: 'Верность',
  KOTOFEY: 'Котофей',
  MOUSER: 'Мышелов',
  PREMIUM: 'Премиум',
  FORTUNE: 'Фортуна',
  KITTY: 'Китти',
  MAU: 'Мау',
  BONIFACE: 'Бонифаций',
  STRIPED: 'Полосатый',
  FAVORITE: 'Любимец',
  MURZIK: 'Мурзик',
  ZOOCENTER: 'Зооцентр',
};

export const SPECIALITIES = {
  CEO: 'ceo',
  CAPTAIN: 'captain',
  SUPPLY: 'supply',
  SALES: 'sales',
  MARKETEER: 'marketeer',
  DOCTOR: 'doctor',
};

export const SPECIALITIES_RUSSIAN_NAME = {
  [SPECIALITIES.CEO]: 'СЕО',
  [SPECIALITIES.CAPTAIN]: 'капитан',
  [SPECIALITIES.SUPPLY]: 'закупщик',
  [SPECIALITIES.SALES]: 'продажник',
  [SPECIALITIES.MARKETEER]: 'маркетолог',
  [SPECIALITIES.DOCTOR]: 'доктор',
};

// Если импортировать из teamMode.js, то происходит ошибка кроссимпорта
// Поэтому создаем отдельно
export const PRIVILEGES = {
  ACKNOWLEDGE_TRADING_LOT: 'acknowledge_trading_lot',
  APPROVE_BY_DOCTOR: 'approve_by_doctor',
  APPROVE_CATS_PURCHASE_BY_CEO: 'approve_cats_purchase_by_ceo',
  APPROVE_CATS_SELL_BY_CEO: 'approve_cats_sell_by_ceo',
  APPROVE_CITY_BUY_TASK_BY_CAPTAIN: 'approve_city_buy_task_by_captain',
  APPROVE_CITY_BUY_TASK_BY_CEO: 'approve_city_buy_task_by_ceo',
  APPROVE_CITY_SALE_TASK_BY_MARKET: 'approve_city_sale_task_by_market',
  APPROVE_CITY_SELL_TASK_BY_CAPTAIN: 'approve_city_sell_task_by_captain',
  APPROVE_CITY_SELL_TASK_BY_CEO: 'approve_city_sell_task_by_ceo',
  APPROVE_CITY_TRADE_BY_SALES: 'approve_city_trade_by_sales',
  APPROVE_CITY_TRADE_BY_SUPPLY: 'approve_city_trade_by_supply',
  APPROVE_CREDIT_REPAY_BY_CAPTAIN: 'approve_credit_repay_by_captain',
  APPROVE_CREDIT_REPAY_BY_CEO: 'approve_credit_repay_by_ceo',
  APPROVE_DRUGS_PURCHASE_BY_CAPTAIN: 'approve_drugs_purchase_by_captain',
  APPROVE_DRUGS_PURCHASE_BY_CEO: 'approve_drugs_purchase_by_ceo',
  APPROVE_FOOD_PURCHASE_BY_CAPTAIN: 'approve_food_purchase_by_captain',
  APPROVE_FOOD_PURCHASE_BY_CEO: 'approve_food_purchase_by_ceo',
  APPROVE_GET_CREDIT_BY_CAPTAIN: 'approve_get_credit_by_captain',
  APPROVE_GET_CREDIT_BY_CEO: 'approve_get_credit_by_ceo',
  APPROVE_HOUSE_PURCHASE_BY_CAPTAIN: 'approve_house_purchase_by_captain',
  APPROVE_HOUSE_PURCHASE_BY_CEO: 'approve_house_purchase_by_ceo',
  APPROVE_INSURANCE_PURCHASE_BY_CAPTAIN: 'approve_insurance_purchase_by_captain',
  APPROVE_MATING_BY_CAPTAIN: 'approve_mating_by_captain',
  APPROVE_MATING_BY_CEO: 'approve_mating_by_ceo',
  APPROVE_PURCHASE_TASK_BY_MARKET: 'approve_purchase_task_by_market',
  APPROVE_SALE_TASK_BY_MARKET: 'approve_sale_task_by_market',
  APPROVE_SHOWCASE_PRICES_BY_CAPTAIN: 'approve_showcase_prices_by_captain',
  APPROVE_SHOWCASE_PRICES_BY_CEO: 'approve_showcase_prices_by_ceo',
  APPROVE_SHOWCASE_PRICES_BY_MARKET: 'approve_showcase_prices_by_market',
  APPROVE_TASK_BY_CAPTAIN: 'approve_task_by_captain',
  APPROVE_TASK_BY_SALES: 'approve_task_by_sales',
  APPROVE_TASK_BY_SUPPLY: 'approve_task_by_supply',
  BUY_DRUGS: 'buy_drugs',
  BUY_HOUSE: 'buy_house',
  BUY_INSURANCE: 'buy_insurance',
  CAN_DO_TO_REVISION: 'can_do_to_revision',
  CANCEL_BUY_REQUEST_BY_CAPTAIN: 'cancel_buy_request_by_captain',
  CANCEL_OWN_TASK: 'cancel_own_task',
  CANCEL_SELL_REQUEST_BY_CAPTAIN: 'cancel_sell_request_by_captain',
  CITY_BUY_PUSH_TO_CAPTAIN: 'city_buy_push_to_captain',
  CITY_SELL_PUSH_TO_CAPTAIN: 'city_sell_push_to_captain',
  CREATE_CANCEL_BUY_REQUEST_TASK: 'create_cancel_buy_request_task',
  CREATE_CANCEL_SELL_REQUEST_TASK: 'create_cancel_sell_request_task',
  CREATE_CITY_PURCHASE_TASK: 'create_city_purchase_task',
  CREATE_CITY_SELL_TASK: 'create_city_sell_task',
  CREATE_PURCHASE_TASK: 'create_purchase_task',
  CREATE_SALE_TASK: 'create_sale_task',
  CREATE_SHOWCASE_PRICES_TASK: 'create_showcase_prices_task',
  CREDIT_REPAY: 'credit_repay',
  DOCTOR_REQUEST: 'doctor_request',
  DRUGS_PURCHASE_REQUEST: 'drugs_purchase_request',
  FOOD_PURCHASE_REQUEST: 'food_purchase_request',
  GET_CREDIT: 'get_credit',
  MATING_REQUEST: 'mating_request',
  PUSH_TO_CAPTAIN: 'push_to_captain',
  CREATE_LTD_TASK: 'create_ltd_task',
  CREATE_CHANGE_LTD_REQUEST: 'create_change_ltd_request',
  APPROVE_LTD_TASK_BY_MARKET: 'approve_ltd_task_by_market',
  LTD_PAYMENT_ACTION_BY_MARKET: 'ltd_payment_action_by_market',
  APPROVE_LTD_TASK: 'approve_ltd_task',
  PUSH_LTD_TO_CAPTAIN: 'push_ltd_to_captain',
  PUSH_LTD_TO_CEO: 'push_ltd_to_CEO',
  FEED_ALL_CATS_REQUEST: 'fed_all_cats_request',
  APPROVE_FEED_ALL_CATS_BY_CAPTAIN: 'approve_fed_all_cats_by_captain',
  APPROVE_FEED_ALL_CATS_BY_CEO: 'approve_fed_all_cats_by_ceo',
};

export const PRIVILEGES_GRAPH = {
  [PRIVILEGES.APPROVE_TASK_BY_SUPPLY]: [PRIVILEGES.APPROVE_PURCHASE_TASK_BY_MARKET, PRIVILEGES.PUSH_TO_CAPTAIN],
  [PRIVILEGES.APPROVE_TASK_BY_SALES]: [PRIVILEGES.APPROVE_SALE_TASK_BY_MARKET, PRIVILEGES.PUSH_TO_CAPTAIN],
  [PRIVILEGES.PUSH_TO_CAPTAIN]: [PRIVILEGES.APPROVE_TASK_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_TASK_BY_CAPTAIN]: [PRIVILEGES.APPROVE_CATS_PURCHASE_BY_CEO, PRIVILEGES.APPROVE_CATS_SELL_BY_CEO],
  [PRIVILEGES.APPROVE_CITY_TRADE_BY_SALES]: [
    PRIVILEGES.APPROVE_CITY_SALE_TASK_BY_MARKET,
    PRIVILEGES.CITY_SELL_PUSH_TO_CAPTAIN,
  ],
  [PRIVILEGES.APPROVE_CITY_TRADE_BY_SUPPLY]: [
    PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CAPTAIN,
    PRIVILEGES.CITY_BUY_PUSH_TO_CAPTAIN,
  ],
  [PRIVILEGES.CITY_SELL_PUSH_TO_CAPTAIN]: [PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN]: [PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CEO],
  [PRIVILEGES.CITY_BUY_PUSH_TO_CAPTAIN]: [PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CAPTAIN]: [PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CEO],
  [PRIVILEGES.CREATE_PURCHASE_TASK]: [PRIVILEGES.APPROVE_TASK_BY_SUPPLY],
  [PRIVILEGES.CREATE_SALE_TASK]: [PRIVILEGES.APPROVE_TASK_BY_SALES],
  [PRIVILEGES.APPROVE_PURCHASE_TASK_BY_MARKET]: [PRIVILEGES.APPROVE_TASK_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_SALE_TASK_BY_MARKET]: [PRIVILEGES.APPROVE_TASK_BY_CAPTAIN],
  [PRIVILEGES.CREATE_SHOWCASE_PRICES_TASK]: [PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_MARKET],
  [PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_MARKET]: [PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_CAPTAIN]: [PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_CEO],
  [PRIVILEGES.CREATE_CANCEL_BUY_REQUEST_TASK]: [PRIVILEGES.CANCEL_BUY_REQUEST_BY_CAPTAIN],
  [PRIVILEGES.CREATE_CANCEL_SELL_REQUEST_TASK]: [PRIVILEGES.CANCEL_SELL_REQUEST_BY_CAPTAIN],
  [PRIVILEGES.BUY_HOUSE]: [PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CAPTAIN]: [PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CEO],
  [PRIVILEGES.FOOD_PURCHASE_REQUEST]: [PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CAPTAIN]: [PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CEO],
  [PRIVILEGES.GET_CREDIT]: [PRIVILEGES.APPROVE_GET_CREDIT_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_GET_CREDIT_BY_CAPTAIN]: [PRIVILEGES.APPROVE_GET_CREDIT_BY_CEO],
  [PRIVILEGES.CREDIT_REPAY]: [PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CAPTAIN]: [PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CEO],
  [PRIVILEGES.BUY_INSURANCE]: [PRIVILEGES.APPROVE_INSURANCE_PURCHASE_BY_CAPTAIN],
  [PRIVILEGES.DRUGS_PURCHASE_REQUEST]: [PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CAPTAIN]: [PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CEO],
  [PRIVILEGES.MATING_REQUEST]: [PRIVILEGES.APPROVE_MATING_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_MATING_BY_CAPTAIN]: [PRIVILEGES.APPROVE_MATING_BY_CEO],
  [PRIVILEGES.CREATE_CITY_PURCHASE_TASK]: [PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CAPTAIN],
  [PRIVILEGES.CREATE_CITY_SELL_TASK]: [PRIVILEGES.APPROVE_CITY_TRADE_BY_SALES],
  [PRIVILEGES.APPROVE_CITY_SALE_TASK_BY_MARKET]: [PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN],
  [PRIVILEGES.FEED_ALL_CATS_REQUEST]: [PRIVILEGES.APPROVE_FEED_ALL_CATS_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_FEED_ALL_CATS_BY_CAPTAIN]: [PRIVILEGES.APPROVE_FEED_ALL_CATS_BY_CEO],
};

export const ADDITIONAL_PRIVILEGES = [PRIVILEGES.CREATE_PURCHASE_TASK, PRIVILEGES.CREATE_SALE_TASK];

// Права, присваиваемые при добавлении новой роли
export const SPECIALITIES_DEFAULT_PRIVILEGES = {
  [SPECIALITIES.MARKETEER]: [
    PRIVILEGES.APPROVE_SALE_TASK_BY_MARKET,
    PRIVILEGES.GET_CREDIT,
    PRIVILEGES.APPROVE_PURCHASE_TASK_BY_MARKET,
    PRIVILEGES.CREDIT_REPAY,
    PRIVILEGES.CREATE_SHOWCASE_PRICES_TASK,
    PRIVILEGES.APPROVE_CITY_SALE_TASK_BY_MARKET,
    PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_MARKET,
  ],
  [SPECIALITIES.SALES]: [
    PRIVILEGES.APPROVE_TASK_BY_SALES,
    PRIVILEGES.CREATE_CITY_SELL_TASK,
    PRIVILEGES.ACKNOWLEDGE_TRADING_LOT,
    PRIVILEGES.CANCEL_OWN_TASK,
    PRIVILEGES.APPROVE_CITY_TRADE_BY_SALES,
    PRIVILEGES.CREATE_SALE_TASK,
    PRIVILEGES.DOCTOR_REQUEST,
    PRIVILEGES.CREATE_CANCEL_SELL_REQUEST_TASK,
  ],
  [SPECIALITIES.SUPPLY]: [
    PRIVILEGES.CREATE_CITY_PURCHASE_TASK,
    PRIVILEGES.CREATE_CANCEL_BUY_REQUEST_TASK,
    PRIVILEGES.CREATE_PURCHASE_TASK,
    PRIVILEGES.APPROVE_TASK_BY_SUPPLY,
  ],
  [SPECIALITIES.DOCTOR]: [
    PRIVILEGES.MATING_REQUEST,
    PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CAPTAIN,
    PRIVILEGES.APPROVE_BY_DOCTOR,
    PRIVILEGES.BUY_HOUSE,
    PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CAPTAIN,
    PRIVILEGES.FOOD_PURCHASE_REQUEST,
    PRIVILEGES.BUY_INSURANCE,
    PRIVILEGES.DRUGS_PURCHASE_REQUEST,
    PRIVILEGES.FEED_ALL_CATS_REQUEST,
  ],
};

export const PRIVILEGES_I18N_RU = {
  [PRIVILEGES.CREATE_PURCHASE_TASK]: 'покупка котят у питомника',
  [PRIVILEGES.CREATE_SALE_TASK]: 'продажа котят питомнику',
};

export const LTD_STATUS = {
  TERMS_AGREEMENT: 'terms_agreement',
  ACTIONS_PHASE: 'actions_phase',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  TERMINATING: 'terminating',
  TERMINATED: 'terminated',
};

export const LTD_FINES_PAYMENT = {
  EMPTY: 'empty',
  FIXED: 'fixed',
  PCT_OF_DEAL_AMOUNT: 'pct_of_deal_amount',
  PCT_OF_MISSED_CATS: 'pct_of_missed_cats',
  PCT_OF_OVERDUE_PAYMENT: 'pct_of_overdue_payment',
  PCT_OF_SICK_CATS: 'pct_of_sick_cats',
};

export const LTD_FINES_PAYMENT_NAME = {
  [LTD_FINES_PAYMENT.FIXED]: 'фиксированный размер',
  [LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT]: '% от суммы сделки',
  [LTD_FINES_PAYMENT.PCT_OF_MISSED_CATS]: '% от недопоставленных котов',
  [LTD_FINES_PAYMENT.PCT_OF_OVERDUE_PAYMENT]: '% от просроченной оплаты',
  [LTD_FINES_PAYMENT.PCT_OF_SICK_CATS]: '% от цены больных котиков',
};

export const LTD_REAL_TIME_FINES_PAYMENT_NAME = {
  [LTD_FINES_PAYMENT.FIXED]: 'фиксированный размер',
  [LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT]: '% от суммы сделки',
  [LTD_FINES_PAYMENT.PCT_OF_MISSED_CATS]: '% от недопоставленных котов',
  [LTD_FINES_PAYMENT.PCT_OF_OVERDUE_PAYMENT]: '% от просроченной оплаты',
  [LTD_FINES_PAYMENT.PCT_OF_SICK_CATS]: '% от цены некачественных котиков',
};

export const LOW_QUALITY_CRITERIES = {
  HUNGRY_CATS: 'hungry_cats',
  SICK_CATS: 'sick_cats',
  SICK_AND_HUNGRY_CATS: 'sick_and_hungry_cats',
};

export const LOW_QUALITY_CRITERIES_NAME = {
  [LOW_QUALITY_CRITERIES.HUNGRY_CATS]: 'Голодные коты',
  [LOW_QUALITY_CRITERIES.SICK_CATS]: 'Больные коты',
  [LOW_QUALITY_CRITERIES.SICK_AND_HUNGRY_CATS]: 'Больные или голодные коты',
};

export const LTD_FINES_TYPE = {
  OVERDUE_PAYMENT: 'overdue_payment',
  OVERDUE_DELIVERY: 'overdue_delivery',
  LOW_QUALITY: 'low_quality',
};

export const LTD_FINES_TYPE_NAME = {
  [LTD_FINES_TYPE.OVERDUE_PAYMENT]: 'просрочку оплаты',
  [LTD_FINES_TYPE.OVERDUE_DELIVERY]: 'просрочку или недопоставку котиков',
  [LTD_FINES_TYPE.LOW_QUALITY]: 'качество котиков',
};

export const MATING_STATUS = {
  RESTED: 'rested',
  MATING: 'mating',
  TIRED: 'tired',
};

export const SICKNESS = {
  FRACTURE: 'fracture',
  RINGWORM: 'ringworm',
  POISONING: 'poisoning',
  FLEAS: 'fleas',
  NONE: 'none',
};

export const CUSTOM_ACTION_NAMES = {
  ON_NEXT_BUTTON: 'onNextButton',
  ON_SELECT_OPEN: 'onSelectOpen',
  ON_LTD_ROW_OPENING_CHANGING: 'onLtdRowOpeningChanging',
};

export const DRAGGABLE_ITEM_TYPE = {
  BUTTON: 'button',
  CAT: 'cat',
};

export const DRAG_AREA = {
  SHOP: 'shop',
  NURSERY: 'nursery',
  CITY: 'city',
  LOT: 'lot',
  SHOP_CATS: 'shop-cats',
  NURSERY_CATS: 'nursery-cats',
  NURSERY_HOUSE_LEFT_LEFT: 'nursery-house-left-left',
  NURSERY_HOUSE_LEFT_RIGHT: 'nursery-house-left-right',
  NURSERY_HOUSE_RIGHT_LEFT: 'nursery-house-right-left',
  NURSERY_HOUSE_RIGHT_RIGHT: 'nursery-house-right-right',
  NURSERY_HOUSE_CHILD_LEFT: 'nursery-house-child-left',
  NURSERY_HOUSE_CHILD_RIGHT: 'nursery-house-child-right',
  CAT: 'cat',
  BUTTONS: 'buttons',
  STORAGE_MY_CATS: 'storage_my_cats',
  STORAGE: 'storage',
  // Для корпоративной версии
  // Транспортировка ↓
  TRANSPORTATION_TOP: 'transportation_top',
  TRANSPORTATION_ZONE: 'transportation_zone',
  TRANSPORTATION_BOTTOM: 'transportation_bottom',
  // Трансфер ↓
  IMPORT_EXPORT_ZONE: 'import_export_zone',
  TRANSFER_ZONE: 'transfer_zone',
  COMPANY_ZONE: 'company_zone',
};

export const DRAG_BUTTON_TYPE = {
  EAT: 'eat',
  MAGNIFIER: 'magnifier',
};

export const SOCKET_READY_STATE = {
  CONNECTING: 'CONNECTING',
  OPEN: 'OPEN',
  CLOSING: 'CLOSING',
  CLOSED: 'CLOSED',
};

export const SOCKET_READY_STATE_BY_STATUS = {
  0: SOCKET_READY_STATE.CONNECTING,
  1: SOCKET_READY_STATE.OPEN,
  2: SOCKET_READY_STATE.CLOSING,
  3: SOCKET_READY_STATE.CLOSED,
};

export const PLAYER_ROLE = {
  SHOP: 'shop',
  NURSERY: 'nursery',
};

export const SOCKET_CLOSING_CODES = {
  IS_CHANGE_PLAYER_ROLE: 3001,
};
